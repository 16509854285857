<template>
  <div class="toolbox-container">
    <div class="handle" @click.stop="toggleToolbox($event)">
      <span >
        工具箱
      </span>
      <span >
        <svg-icon :icon-class="toolboxIcon"></svg-icon>
      </span>
    </div>
    <div class="toolbox">
      <div class="block" @mouseenter.stop="showToolboxLayer('telVisible')" @mouseleave.stop="hideToolboxLayer('telVisible')">
        <svg-icon icon-class="dianhua" class="icon" style="font-size:2rem;"></svg-icon>
        <div>
          电话
        </div>
        <div class="show-layer" v-show="showSiteInfo && telVisible">
          {{siteInfo.info.tel}}
        </div>
      </div>
      <div class="block" @mouseenter.stop="showToolboxLayer('qqVisible')" @mouseleave.stop="hideToolboxLayer('qqVisible')">
        <svg-icon icon-class="qq" class="icon" style="font-size:2rem;"></svg-icon>
        <div>
          客服
        </div>
        <div class="show-layer" v-show="showSiteInfo && qqVisible">
          {{siteInfo.info.qq}}
        </div>
      </div>
      <div class="block" @mouseenter.stop="showToolboxLayer('serviceVisible')" @mouseleave.stop="hideToolboxLayer('serviceVisible')">
        <svg-icon icon-class="shouhou" class="icon" style="font-size:2rem;"></svg-icon>
        <div>
          售后
        </div>
        <div class="show-layer" v-show="showSiteInfo && serviceVisible">
          {{siteInfo.info.serverTel}}
        </div>
      </div>
      <div class="block" @mouseenter.stop="showToolboxLayer('wechatVisible')" @mouseleave.stop="hideToolboxLayer('wechatVisible')">
        <svg-icon icon-class="ico" class="icon" style="font-size:2rem;"></svg-icon>
        <div>
          微信
        </div>
        <div class="show-layer-wechat" v-show="showSiteInfo && wechatVisible">
          <img :src="siteInfo.pic.wxLogo" >
        </div>
      </div>
      <div class="block" @click.stop="toTop">
        <svg-icon icon-class="zhiding" class="icon" style="font-size:2rem;"></svg-icon>
        <div>
          回顶部
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { setLocalStorage, getLocalStorage } from '@/utils/auth';
import {scrollTo} from '@/utils/scroll-to'


export default {
  data() {
    return {
      toolboxIcon:'zuobian',
      toolboxShow:false,
      siteInfo:{},
      telVisible:false,
      qqVisible:false,
      serviceVisible:false,
      wechatVisible:false,
	  showSiteInfo: true,
    }
  },
  components: {

  },
  props:{

  },
  created(){
    this.findSiteInfo()
  },
  methods:{
    findSiteInfo(){
		this.showSiteInfo = getLocalStorage('showSiteInfo')
		this.siteInfo = getLocalStorage('siteInfo')
    },
    showToolboxLayer(index){
      eval('this.' + index + ' = true')
    },
    hideToolboxLayer(index){
      eval('this.' + index + ' = false')
    },
    toggleToolbox(event){
      let toolboxContainer = document.querySelector(".toolbox-container");
      if(this.toolboxShow == true){
        toolboxContainer.style.right = '-72px';
        this.toolboxShow = false;
        this.toolboxIcon = 'zuobian';
      }else{
        toolboxContainer.style.right = '0px';
        this.toolboxShow = true;
        this.toolboxIcon = 'youbian';
      }
    },
    toTop(){
      scrollTo(0,500)
    },
  }
}
</script>

<style scoped lang="scss">
.toolbox-container{
  transition-property: right;
  transition-duration: 1s;
  display:flex;
  position:fixed;
  width:100px;
  height:450px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index:999;
  right:-72px;
  // top:80px;
  .handle{
    width:28px;
    height:80px;
    // border-radius: 5px 0px 0px 5px;
    background-color:$theme-color;
    color:white;
    cursor:pointer;
    padding:15px 5px;
    display:flex;
    flex-direction: column;
    margin-right: 2px;
    text-align: center;
  }
  .toolbox{
    background-color:transparent;
    height:380px;
    width:70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .block{
      // border-radius: 5px;
      height:70px;
      width:70px;
      text-align: center;
      background-color:$theme-color;
      cursor:pointer;
      position: relative;
      color:#eee;
      .show-layer{
        position: absolute;
        top:0px;
        right:71px;
        background-color:white;
        width:240px;
        height:68px;
        font-size:24px;
        line-height: 70px;
        font-weight: bold;
        color:$theme-color;
        border:solid 1px $theme-color;
      }
      .show-layer-wechat{
        height:160px;
        width:160px;
        position: absolute;
        top:0px;
        right:71px;
        background-color:white;
        font-weight: bold;
        color:$theme-color;
        border:solid 1px $theme-color;
      }
    }
    .block:hover{
      background-color:#ddd;
      color:$theme-color;
    }
    .icon{
      margin-top: 10px;
    }
  }

}
</style>