<template>
  <div class="webfooter-container">
    <div class="container">
      <div class="links">
        <span class="title">
          友情链接：
        </span>
        <span class="text" v-for="(link,index) in links" :key="index">
            <a :href="link.url">{{link.title}}</a>
        </span>
      </div>
      <div class="bottom" v-if="showSiteInfo">
        <div class="left">
          <span class="title">
            快速导航
          </span>
          <div class="content">
            <div class="content-left">
              <div><router-link to="/">网站首页</router-link></div>
              <div><router-link to="/course">课程中心</router-link></div>
              <div><router-link to="/exam">在线题库</router-link></div>
              <div><router-link to="/news">考试资讯</router-link></div>
            </div>
            <div class="content-right">
              <div><router-link to="/about/1">关于我们</router-link></div>
              <div><router-link to="/about/2">联系我们</router-link></div>
              <div><router-link to="/howbuy">如何购课</router-link></div>
              <div><router-link to="/howpay">如何支付</router-link></div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="content">
            <div class="contact-way">
              <div class="title">
                联系方式
              </div>
              <div>
                客服热线：{{siteInfo.info.tel}} {{ siteInfo.info.serverTel}}
              </div>
              <div>
                公司地址：{{siteInfo.info.address}}
              </div>
              <div>
                版权所有：{{siteInfo.info.copyright}}
                {{siteInfo.info.sitename}}
              </div>
              <div>
                <a href="https://beian.miit.gov.cn/" target="_blank">{{siteInfo.info.icp}}</a>
                {{siteInfo.info.ga_icp}}
              </div>
            </div>
            <div class="wechat-logo">
              <img :src="siteInfo.pic.wxLogo">
            </div>
          </div>
        </div>
      </div>
      <div class="bottom nologo-content" v-else>
        <a href="https://beian.miit.gov.cn/" target="_blank">{{siteInfo.info.icp}}</a>
                {{siteInfo.info.ga_icp}}
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import {getLinksInfo,getSiteInfo} from '@/api/system'
import {getLocalStorage} from '@/utils/auth'
export default {
  data() {
    return {
      links:[],
      siteInfo:{},
	  showSiteInfo: true,
    }
  },
  components: {

  },
  props:{

  },
  created(){
    this.findLinks()
    this.findSiteInfo()
  },
  methods:{
    findLinks(){
      getLinksInfo({}).then(res => {
        if(res.code == 1){
          this.links = res.data
        }
      })
    },
    findSiteInfo(){
		this.showSiteInfo = getLocalStorage('showSiteInfo')
		this.siteInfo = getLocalStorage('siteInfo')
    }
  },
}
</script>

<style scoped lang="scss">
.webfooter-container{
  background-color:$footer-color;
  width:100%;
  .container{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    .links{
      overflow: hidden;
      border-bottom: solid 1px #666;
      line-height: 60px;
      .title{
        font-weight:bold;
        color:#eee;
      }
      .text{
        display:inline-block;
        margin:0px 10px;
        color:#eee;
        a{
          color:#eee;
          &:hover{
            color:$theme-color;
          }
        }
      }
    }
    .bottom{
      display: flex;
      flex-direction: row;
      padding-bottom: 50px;
      padding-top:10px;
      .left{
        height:150px;
        width:300px;
        .title{
          font-weight:bold;
          color:#eee;
          line-height: 40px;
        }
        .content{
          display:flex;
          flex-direction:row;
          line-height: 30px;
          .content-left{
            width:150px;
            padding-left:30px;
          }
          .content-right{
            width:150px;
          }
          a{
            color:#eee;
            &:hover{
              color:$theme-color;
            }
          }
        }
      }
      .right{
        height:150px;
        width:900px;
        .content{
          display:flex;
          flex-direction: row;
          justify-content: space-between;
          line-height:30px;
          .title{
            font-weight:bold;
            color:#eee;
            line-height: 40px;
          }
          .contact-way{
            width:700px;
            color:#eee;
            a{
              color:#eee;
              &:hover{
                color:$theme-color;
              }
            }
          }
          .wechat-logo{
            height:150px;
            width:150px;
          }
        }
        
      }
    }
  }
}
.nologo-content{
  width:1200px;
  a{
    color:#eee;
    &:hover{
      color:$theme-color;
    }
  }
}
</style>