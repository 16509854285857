<template>
  <div class="navbar-container">
    <div class="header-top">
      <div class="header">
        <div class="header-left">
<!--          <span>
            <router-link to="/">首页</router-link>
          </span>
          <span>
            |
          </span> -->
          <span>
            <router-link to="/course">课程</router-link>
          </span>
		  <span>
		    |
		  </span>
          <span>
            <router-link to="/exam">题库</router-link>
          </span>
          <span>
            |
          </span>
          <span>
            <router-link to="/news">资讯</router-link>
          </span>
          <span>
            |
          </span>
          <span>
            <router-link to="/teacher">师资</router-link>
          </span>
		  <span>
		    |
		  </span>
		  <span>
		    <router-link to="/live">直播</router-link>
		  </span>
        </div>
        <div class="header-right">
          <span v-if="siteInfo.info">
            <svg-icon icon-class="dianhua"></svg-icon>
            {{ siteInfo.info ? siteInfo.info.serverTel : '' }}
          </span>
          <span v-if="siteInfo.info" @click="toNaviQQ(siteInfo.info.qq)" class="cursor-pointer">
            <svg-icon icon-class="qq"></svg-icon>
            客服
          </span>
          <span>
            <router-link to="/app">
              <svg-icon icon-class="APP"></svg-icon>
              App客户端
            </router-link>
          </span>
          <span v-if="!userInfo">
            <router-link to="/login">
              <svg-icon icon-class="denglu"></svg-icon>
              登录
            </router-link> 
            |
            <router-link to="/register"> 
              <svg-icon icon-class="register"></svg-icon>
              注册
            </router-link>
          </span>
          <span v-else class="my-el-dropdown">
            <el-dropdown>
              <span class="el-dropdown-link">
                <svg-icon icon-class="touxiang"></svg-icon>
                {{userInfo?userInfo.name:''}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                <el-dropdown-item style="font-size:14px;height:30px;line-height:30px;padding:0px 40px 0px 20px;">
                  <router-link to="/user/mycourse">
                    <svg-icon icon-class="kecheng-"></svg-icon>
                    我的课程
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item style="font-size:14px;height:30px;line-height:30px;padding:0px 40px 0px 20px;">
                  <router-link to="/user/myorder">
                    <svg-icon icon-class="wodedingdan"></svg-icon>
                    我的订单
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item style="font-size:14px;height:30px;line-height:30px;padding:0px 40px 0px 20px;">
                  <router-link to="/user/myexam">
                    <svg-icon icon-class="icon_tikubianxie-01"></svg-icon>
                    我的题库
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item style="font-size:14px;height:30px;line-height:30px;padding:0px 40px 0px 20px;">
                  <router-link to="/user/mycollect" >
                    <svg-icon icon-class="shoucang"></svg-icon>
                    我的收藏
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item style="font-size:14px;height:30px;line-height:30px;padding:0px 40px 0px 20px;">
                  <router-link to="/user/mywrong" >
                    <svg-icon icon-class="bianji1"></svg-icon>
                    我的错题
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item style="font-size:14px;height:30px;line-height:30px;padding:0px 40px 0px 20px;">
                  <router-link to="/user/myinfo">
                    <svg-icon icon-class="gerenxinxiicon"></svg-icon>
                    我的信息
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item style="font-size:14px;height:30px;line-height:30px;padding:0px 40px 0px 20px;">
                  <router-link to="/user/myquestion">
                    <svg-icon icon-class="dayi"></svg-icon>
                    答疑服务
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item divided style="font-size:14px;height:30px;line-height:30px;padding:0px 40px 0px 20px;">
                  <!-- <router-link @click="toLogout" to="/login"> -->
                  <a href="/login"	@click="removeMer">
                    <svg-icon icon-class="icon4"></svg-icon>
                    退出系统
                  </a>
                  <!-- </router-link> -->
                </el-dropdown-item>
              </el-dropdown-menu>
              </template>
              
            </el-dropdown>
          </span>
        </div>
      </div>
    </div>
    <div class="header-navbar">
      <div class="navbar">
        <div class="navbar-left">
          <span @click="toIndex" v-if="showSiteInfo" class="logo cursor-pointer" :style="{backgroundImage:'url(\'' + (siteInfo.pic ? siteInfo.pic.logo : '') + '\')'}">
          </span>
          <span @click="toIndex" v-else class="logo cursor-pointer" :style="{backgroundImage:'url(\'' + nologo + '\')'}">
          </span>
          <a class="bar" href="/course">课程中心</a>
          <a class="bar" href="/exam">在线题库</a>
          <a class="bar" href="/news">新闻资讯</a>
          <a class="bar" href="/live">在线直播</a>
					<a class="bar" @click="toAdvert" style="margin-left: 30px;cursor:pointer;" v-if="siteInfo.menu">{{siteInfo.menu.title}}</a>
					<!-- 
						1. 有自定义菜单——>渲染自定义菜单
						2. 菜单跳转广告页面——>广告根据ID查 -->
        </div>
        <div class="navbar-right">
          <div class="search-input-container" >
            <input class="keyword-input" type="text" v-model="keyword" placeholder="查询套餐/题库/资讯/导师" @keyup.enter="toSearch">
            <button class="search-button cursor-pointer" @click="toSearch">
              <svg-icon icon-class="sousuo"></svg-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import {setLocalStorage,getLocalStorage,removeLocalStorage} from '@/utils/auth'
import {getSiteInfo,getUserInfo} from '@/api/system'
import nologo from '@/assets/images/nologo.png'

export default {
  data() {
    return {
      siteInfo:'',
      userInfo:null,
      token:'',
      nologo,
      searchType: 1,
      keyword:'',
	  showSiteInfo: true,
    }
  },
  components: {

  },
  props: {
		
	},
  created(){
    this.findUserInfo()
  },
	mounted(){
		this.findSiteInfo()
	},
  methods:{
		removeMer(){
			
		},
    findSiteInfo(){
			this.showSiteInfo = getLocalStorage('showSiteInfo');
			this.siteInfo = getLocalStorage('siteInfo');
			if(this.siteInfo?.menu){//有自定义
				//外边距缩小至： margin-left:30px;
				$(".navbar-left a").css({
					"margin-left":"30px"
				});
			}
    },
    findUserInfo(){
      if(getLocalStorage('token')){
        let _userInfo = getLocalStorage('userInfo') || null
        if(_userInfo){
          this.userInfo = _userInfo
        }else{
          getUserInfo().then(
            res=>{
              if(res.code == 1){
                setLocalStorage('userInfo',res.data)
                this.userInfo = res.data
              }
            }
          )
        }
      }else{
        this.userInfo = null;
      }
    },
		toAdvert(){
			this.$router.push({
					name: 'Advert',
					params: this.siteInfo.menu
			})
		},
    toNaviQQ(qq){
      window.location.href = 'http://wpa.qq.com/msgrd?v=3&uin=' + qq + '&site=qq&menu=yes'
    },
    toSearch(){
      if(this.keyword){
        this.$router.push('/search/' + window.encodeURIComponent(this.keyword))
      }
    },
    toIndex(){
      this.$router.push('/')
    }
  }
}
</script>
<style scoped lang="scss">
.cursor-pointer{
  cursor:pointer;
}
.navbar-container{
  // position: relative;
  height:140px;
  // border-bottom: solid 1px #eee;
}
.header-top{
  background-color:#eee;
  .header{
    display:flex;
    flex-direction: row;
    height:40px;
    line-height: 40px;
    justify-content: space-between;
    width:1200px;
    margin:0px auto;
    .header-left{
      span{
        margin-right:8px;
      }
    }
    .header-right{
      span{
        margin-left:18px;
      }
    }
  }
}
.header-navbar{
  background-color:#fff;
  .navbar{
    display:flex;
    flex-direction: row;
    height:100px;
    justify-content: flex-start;
    width:1200px;
    margin:0px auto;
    .navbar-left{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width:800px;
      .logo{
        display:inline-block;
        height:80px;
        width:240px;
        margin:20px 0px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
      a{
        color:#333;
        font-size:20px;
        height:24px;
        margin-left:50px;
        font-weight: bolder;
        display:inline-block;
        padding-bottom: 5px;
        &:hover{
          color:$theme-color;
          box-shadow: 0px 2px 0px 0px $theme-color;
        }
      }
    }
    .navbar-right{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width:400px;
      .search-input-container{
        width:300px;
        display:flex;
        flex-direction: row;
        .keyword-input{
          height:20px;
          width:240px;
          padding:8px;
          border:none;
          outline:medium;
          color:$text-color;
          border:solid 1px $theme-color;
          border-radius: 0px;
        }
        .search-button{
          border-radius: 0px;
          width:50px;
          height:38px;
          font-size:30px;
          border:none;
          color:white;
          background-color:$theme-color;
        }
      }
    }
  }
}

</style>